<!-- begin panel -->
<div *ngIf="!this.remove"
     [ngClass]="[ (this.expand) ? 'panel-expand': '', (this.reload) ? 'panel-loading': '', (this.panelClass) ? this.panelClass : '', (this.variant) ? 'panel-' + this.variant : 'panel-inverse' ]"
     class="panel">
  <!-- begin panel-heading -->
  <div class="panel-heading">
    <div *ngIf="!this.noButton" class="panel-heading-btn">
      <a (click)="panelExpand()" class="btn btn-xs btn-icon btn-circle btn-default" href="javascript:"><i
        class="fa fa-expand"></i></a>
      <a (click)="panelReload()" class="btn btn-xs btn-icon btn-circle btn-success" href="javascript:"><i
        class="fa fa-redo"></i></a>
      <a (click)="panelCollapse()" class="btn btn-xs btn-icon btn-circle btn-warning" href="javascript:"><i
        class="fa fa-minus"></i></a>
      <a (click)="panelRemove()" class="btn btn-xs btn-icon btn-circle btn-danger" href="javascript:"><i
        class="fa fa-times"></i></a>
    </div>
    <h4 *ngIf="this.title" class="panel-title">{{ title }}</h4>
    <ng-content select="[header]"></ng-content>
  </div>
  <!-- end panel-heading -->

  <!-- begin before-body -->
  <ng-content select="[beforeBody]"></ng-content>
  <!-- end before-body -->

  <!-- begin panel-body -->
  <div *ngIf="!this.noBody" [ngClass]="[ (this.collapse) ? 'd-none': '', (this.bodyClass) ? this.bodyClass : '' ]"
       class="panel-body">
    <ng-content></ng-content>
    <div *ngIf="this.reload" class="panel-loader"><span class="spinner-small"></span></div>
  </div>
  <!-- end panel-body -->

  <!-- begin no-body -->
  <ng-content select="[noBody]"></ng-content>
  <!-- end no-body -->

  <!-- begin outside-body -->
  <ng-content select="[outsideBody]"></ng-content>
  <!-- end outside-body -->

  <!-- begin panel-footer -->
  <div #panelFooter [hidden]="!showFooter" [ngClass]="[ (this.footerClass) ? this.footerClass : '' ]"
       class="panel-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
  <!-- end panel-footer -->

  <!-- begin after-footer -->
  <ng-content select="[afterFooter]"></ng-content>
  <!-- end after-footer -->
</div>
<!-- end panel -->
