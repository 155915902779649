import {AgedissErrorBody} from './agediss-error-body.model';
import {Deserializable} from './interfaces/deserializable.interface';

export class AgedissError implements Deserializable, Error {
  body: AgedissErrorBody;
  message: string;
  name: string;

  deserialize(input: any): this {
    Object.assign(this,input);
    this.body = (input.body) ? new AgedissErrorBody().deserialize(input.body) : null;
    return this;

  }
}
