import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'contestatairePipe'})
export class ContestatairePipe implements PipeTransform {
  transform(value: string, exponent?: number): string {
    switch(value) {
      case 'DO':
        return 'Donneur d\'ordre';
      case 'PF':
        return 'Plateforme';
    }
  }
}
