import {Deserializable} from '../interfaces/deserializable.interface';
import {Motif} from './motif.model';

export class MotifDetail implements Deserializable {

  public id: number;
  public created_at: Date;
  public updated_at: Date;
  public code: string;
  public libelle: string;
  public motif?: Motif;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.motif  = (input.motif) ? new MotifDetail().deserialize(input.motif) : null;
    return this;
  }

}
