import {Deserializable} from '../interfaces/deserializable.interface';

export class TypeApproche implements Deserializable {

  public id: number;
  public created_at: Date;
  public updated_at: Date;
  public code: string;
  public libelle: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
